<template>
  <div id="holderEdit">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_047')}`" />
    <div class="common-card-body">
      <a-form
        ref="editRef"
        class="holder-edit-form ant-round-form"
        layout="vertical"
        :model="editForm"
        :rules="editRules"
      >
        <a-alert v-if="editForm.remark" type="error" banner style="margin-bottom: 24px">
          <template #message>
            <span>{{ $t('pages_holder_010') }}: </span>
            <span>{{ editForm.remark }}</span>
          </template>
        </a-alert>
        <div class="basic-info">{{ $t("pages_holder_011") }}</div>
        <div style="padding: 0 64px">
          <a-form-item name="holderType" :label="$t('pages_holder_012')">
            <a-select
              v-model:value="editForm.holderType"
              :disabled="rejectAttr.includes('holderType')"
              :placeholder="$t('common_text_005') + $t('pages_holder_012')"
              @change="holderTypeChange"
            >
              <a-select-option
                v-for="item in holderTypeList"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="editForm.holderType" name="holderIdentity" :label="$t('pages_holder_003')">
            <a-select
              v-model:value="editForm.holderIdentity"
              :disabled="rejectAttr.includes('holderIdentity')"
              :placeholder="$t('common_text_005') + $t('pages_holder_003')"
            >
              <a-select-option
                v-for="item in identityList"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="['06', '14'].includes(editForm.holderIdentity)" name="reserve2">
            <a-input
              v-model:value="editForm.reserve2"
              allow-clear
              :disabled="rejectAttr.includes('reserve2')"
              :placeholder="$t('common_text_006') + $t('pages_holder_003')"
            />
          </a-form-item>

          <a-form-item v-if="status !== '40' || (status === '40' && !['01', '02', '03'].includes(editForm.holderIdentity))" name="holderProveDocument" :label="$t('pages_holder_013')">
            <upload-auto
              ref="uploadRef"
              v-model:file-content="editForm.holderProveDocument"
              list-type="picture"
              accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
              :disabled="rejectAttr.includes('holderProveDocument')"
              :explain="$t('pages_holder_040')"
              :max-count="10"
            />
          </a-form-item>
        </div>

        <div class="basic-info">{{ $t("pages_holder_014") }}</div>
        <div style="padding: 0 64px">
          <a-form-item name="holderArea" :label="$t('pages_holder_005')">
            <a-select
              v-model:value="editForm.holderArea"
              allow-clear
              show-search
              option-filter-prop="label"
              :disabled="rejectAttr.includes('holderArea')"
              :placeholder="$t('common_text_005') + $t('pages_holder_005')"
              @change="holderAreaChange"
            >
              <a-select-option
                v-for="(item, index) in areaList"
                :key="index"
                :value="item.areaCode"
                :label="`${item.areaEnglishName}-${item.areaChineseName}`"
              >{{ item.areaEnglishName }} - {{ item.areaChineseName }}</a-select-option>
            </a-select>
          </a-form-item>

          <div v-if="editForm.holderType === '00'">
            <a-form-item v-if="['CN', 'HK'].includes(editForm.holderArea)" name="holderName" :label="$t('pages_holder_015')">
              <a-input
                v-model:value="editForm.holderName"
                allow-clear
                :disabled="rejectAttr.includes('holderName')"
                :placeholder="$t('pages_holder_016')"
              />
            </a-form-item>

            <a-form-item name="holderNameEn" :label="$t('pages_holder_002')">
              <a-input
                v-model:value="editForm.holderNameEn"
                allow-clear
                :disabled="rejectAttr.includes('holderNameEn')"
                :placeholder="$t('pages_holder_017')"
              />
            </a-form-item>

            <a-form-item v-if="editForm.holderArea && editForm.holderArea !== 'CN'" name="holderCertificateType" :label="$t('pages_holder_006')">
              <a-select
                v-model:value="editForm.holderCertificateType"
                :disabled="rejectAttr.includes('holderCertificateType')"
                :placeholder="$t('common_text_005') + $t('pages_holder_006')"
              >
                <a-select-option
                  v-for="item in certTypeList"
                  :key="item.code"
                  :value="item.code"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item v-if="editForm.holderArea" name="holderCertificate" :label="$t('pages_holder_049')">
              <upload-auto
                ref="uploadCRef"
                v-model:file-content="editForm.holderCertificate"
                list-type="picture"
                accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                :disabled="rejectAttr.includes('holderCertificate')"
                :explain="editForm.holderArea === 'CN' ? $t('pages_holder_050') : editForm.holderArea === 'HK' ? $t('pages_holder_051') : $t('pages_holder_052')"
                :max-count="10"
              />
            </a-form-item>

            <a-form-item v-if="editForm.holderArea" name="holderCertificateNumber" :label="editForm.holderArea === 'CN' ? $t('pages_holder_018') : $t('pages_holder_007')">
              <a-input
                v-model:value="editForm.holderCertificateNumber"
                allow-clear
                :disabled="rejectAttr.includes('holderCertificateNumber')"
                :placeholder="$t('common_text_006') + (editForm.holderArea === 'CN' ? $t('pages_holder_018') : $t('pages_holder_007'))"
              />
            </a-form-item>

            <a-form-item name="holderRealAddress" :label="$t('pages_holder_020')">
              <a-input
                v-model:value="editForm.holderRealAddress"
                allow-clear
                :disabled="rejectAttr.includes('holderRealAddress')"
                :placeholder="$t('pages_holder_019')"
              />
            </a-form-item>

            <a-form-item name="postalCode" :label="$t('pages_holder_061')">
              <a-input
                v-model:value="editForm.postalCode"
                allow-clear
                :disabled="rejectAttr.includes('postalCode')"
                :placeholder="$t('pages_holder_062')"
              />
            </a-form-item>

            <a-form-item name="holderRegistratDate" :label="$t('pages_holder_021')">
              <a-date-picker
                v-model:value="editForm.holderRegistratDate"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                :disabled="rejectAttr.includes('holderRegistratDate')"
                :placeholder="$t('common_text_005') + $t('pages_holder_021')"
              />
            </a-form-item>

            <a-form-item name="holderSex" :label="$t('pages_holder_022')">
              <a-select
                v-model:value="editForm.holderSex"
                :disabled="rejectAttr.includes('holderSex')"
                :placeholder="$t('common_text_005') + $t('pages_holder_022')"
              >
                <a-select-option
                  v-for="item in sexTypeList"
                  :key="item.code"
                  :value="item.code"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </div>

          <div v-if="editForm.holderType === '01'">
            <div v-if="editForm.holderArea">
              <a-form-item v-if="editForm.holderArea === 'CN'" name="holderName" :label="$t('pages_holder_023')">
                <a-input
                  v-model:value="editForm.holderName"
                  allow-clear
                  :disabled="rejectAttr.includes('holderName')"
                  :placeholder="$t('pages_holder_024')"
                />
              </a-form-item>

              <a-form-item name="holderNameEn" :label="$t('pages_holder_025')">
                <a-input
                  v-model:value="editForm.holderNameEn"
                  allow-clear
                  :disabled="rejectAttr.includes('holderNameEn')"
                  :placeholder="editForm.holderArea === 'CN' ? $t('pages_holder_026') : $t('pages_holder_027')"
                />
              </a-form-item>

              <a-form-item name="holderCertificate" :label="editForm.holderArea === 'CN' ? $t('pages_holder_053') : editForm.holderArea === 'HK' ? $t('pages_holder_054') : $t('pages_holder_055')">
                <upload-auto
                  ref="uploadCRef"
                  v-model:file-content="editForm.holderCertificate"
                  list-type="picture"
                  accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                  :disabled="rejectAttr.includes('holderCertificate')"
                  :explain="editForm.holderArea === 'CN' ? $t('pages_holder_056') : editForm.holderArea === 'HK' ? $t('pages_holder_057') : $t('pages_holder_058')"
                  :max-count="10"
                />
              </a-form-item>

              <a-form-item name="holderCertificateNumber" :label="editForm.holderArea === 'CN' ? $t('pages_holder_028') : editForm.holderArea === 'HK' ? $t('pages_holder_029') : $t('pages_holder_030')">
                <a-input
                  v-model:value="editForm.holderCertificateNumber"
                  allow-clear
                  :disabled="rejectAttr.includes('holderCertificateNumber')"
                  :placeholder="editForm.holderArea === 'CN' ? ($t('common_text_006') + $t('pages_holder_028')) : editForm.holderArea === 'HK' ? $t('pages_holder_029') : $t('pages_holder_030')"
                />
              </a-form-item>
            </div>

            <a-form-item name="holderRegistratDate" :label="$t('pages_holder_031')">
              <a-date-picker
                v-model:value="editForm.holderRegistratDate"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                :disabled="rejectAttr.includes('holderRegistratDate')"
                :placeholder="$t('common_text_005') + $t('pages_holder_031')"
              />
            </a-form-item>

            <a-form-item name="holderRealAddress" :label="$t('pages_holder_032')">
              <a-input
                v-model:value="editForm.holderRealAddress"
                allow-clear
                :disabled="rejectAttr.includes('holderRealAddress')"
                :placeholder="$t('common_text_006') + $t('pages_holder_032')"
              />
            </a-form-item>

            <a-form-item name="postalCode" :label="$t('pages_holder_061')">
              <a-input
                v-model:value="editForm.postalCode"
                allow-clear
                :disabled="rejectAttr.includes('postalCode')"
                :placeholder="$t('pages_holder_062')"
              />
            </a-form-item>

            <div v-for="(item, index) in editForm.finalBeneficialReqs" :key="index" class="final-area">
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialName']" :label="$t('pages_holder_033')" :required="true">
                <a-input
                  v-model:value="item.beneficialName"
                  allow-clear
                  :disabled="rejectAttr.includes('finalBeneficialReqs')"
                  :placeholder="$t('pages_holder_034')"
                />
              </a-form-item>
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialArea']" :label="$t('pages_holder_035')" :required="true">
                <a-select
                  v-model:value="item.beneficialArea"
                  allow-clear
                  show-search
                  option-filter-prop="label"
                  :disabled="rejectAttr.includes('finalBeneficialReqs')"
                  :placeholder="$t('common_text_005') + $t('pages_holder_035')"
                >
                  <a-select-option
                    v-for="area in areaList"
                    :key="area.areaCode"
                    :value="area.areaCode"
                    :label="`${area.areaEnglishName}-${area.areaChineseName}`"
                  >{{ area.areaEnglishName }} - {{ area.areaChineseName }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialCertificateType']" :label="$t('pages_holder_036')" :required="true">
                <a-select
                  v-model:value="item.beneficialCertificateType"
                  allow-clear
                  :disabled="rejectAttr.includes('finalBeneficialReqs')"
                  :placeholder="$t('common_text_005') + $t('pages_holder_036')"
                >
                  <a-select-option
                    v-for="cerType in beneficialCertificateList"
                    :key="cerType.code"
                    :value="cerType.code"
                  >{{ cerType.name }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialCertificateNumber']" :label="$t('pages_holder_037')" :required="true">
                <a-input
                  v-model:value="item.beneficialCertificateNumber"
                  allow-clear
                  :disabled="rejectAttr.includes('finalBeneficialReqs')"
                  :placeholder="$t('common_text_005') + $t('pages_holder_037')"
                />
              </a-form-item>
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialDate']" :label="$t('pages_holder_038')" :required="true">
                <a-date-picker
                  v-model:value="item.beneficialDate"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :disabled="rejectAttr.includes('finalBeneficialReqs')"
                  :placeholder="$t('common_text_005') + $t('pages_holder_038')"
                />
              </a-form-item>
              <a-form-item :name="['finalBeneficialReqs', index, 'beneficialSex']" :label="$t('pages_holder_039')" :required="true">
                <a-select v-model:value="item.beneficialSex" :disabled="rejectAttr.includes('finalBeneficialReqs')" :placeholder="$t('common_text_005') + $t('pages_holder_039')">
                  <a-select-option
                    v-for="sexType in sexTypeList"
                    :key="sexType.code"
                    :value="sexType.code"
                  >{{ sexType.name }}</a-select-option>
                </a-select>
              </a-form-item>
              <div v-if="!rejectAttr.includes('finalBeneficialReqs')" style="margin-top: -12px">
                <a
                  v-if="index === 0 && editForm.finalBeneficialReqs.length < 5"
                  class="flex-align"
                  style="justify-content: end"
                  @click="finalEdit()"
                >
                  <plus-circle-outlined style="margin-right: 5px" />
                  {{ $t('pages_holder_041') }}
                </a>
                <a
                  v-if="index !== 0"
                  class="flex-align"
                  style="justify-content: end; color: #ff4d4f"
                  @click="finalEdit(index)"
                >
                  <minus-circle-outlined style="margin-right: 5px" />
                  {{ $t('pages_holder_042') }}
                </a>
              </div>
            </div>
          </div>

        </div>

        <div style="padding: 0 64px; margin-top: 48px">
          <a-button
            style="min-width: 138px"
            shape="round"
            type="primary"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
          <a-button
            :loading="loading"
            style="min-width: 138px; margin-left: 18px"
            type="primary"
            shape="round"
            class="apply-btn"
            @click="handleSubmit"
          >{{ $t('common_text_002') }}</a-button>
        </div>

      </a-form>

    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api'
import { message } from 'ant-design-vue'
import { getAttrStatusList } from '@/util'
import i18n from '@/locale'
export default {
  name: 'HolderEdit',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup() {
    const editRef = ref(null)
    const uploadRef = ref(null)
    const uploadCRef = ref(null)
    const router = useRouter()
    const route = useRoute()

    const validatorNameEn = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('common_text_006') + i18n.global.t('pages_holder_002'))
      if (!((/^[\x00-\xff]*$/).test(value))) {
        return Promise.reject(i18n.global.t('common_text_046'))
      }
      return Promise.resolve()
    }

    const isRequired = computed(() => {
      return state.status !== '40'
    })

    const state = reactive({
      areaList: [],
      loading: false,
      status: '',
      rejectAttr: [],
      editForm: {
        id: '',
        holderType: null,
        holderIdentity: null,
        reserve2: '',
        holderProveDocument: '',
        holderArea: null,
        holderName: '',
        holderNameEn: '',
        holderCertificateType: null,
        holderCertificate: '',
        holderCertificateNumber: '',
        holderRealAddress: '',
        postalCode: '',
        holderRegistratDate: null,
        holderSex: null,
        finalBeneficialReqs: [{
          beneficialName: '',
          beneficialArea: null,
          beneficialCertificateType: null,
          beneficialCertificateNumber: '',
          beneficialDate: null,
          beneficialSex: null
        }]
      },
      editRules: {
        holderType: [{ required: isRequired }],
        holderIdentity: [{ required: isRequired }],
        reserve2: [{ required: isRequired }],
        holderProveDocument: [{ required: isRequired, message: i18n.global.t('pages_holder_040') }],
        holderArea: [{ required: isRequired }],
        holderName: [{ required: isRequired }],
        holderNameEn: [{ required: true, validator: validatorNameEn }],
        holderCertificateType: [{ required: isRequired }],
        holderCertificate: [{ required: isRequired, message: i18n.global.t('common_text_007') }],
        holderCertificateNumber: [{ required: isRequired }],
        postalCode: [{ required: true }],
        holderRealAddress: [{ required: isRequired }],
        holderRegistratDate: [{ required: isRequired }],
        holderSex: [{ required: true }]
      }
    })

    state.holderTypeList = computed(() => {
      return getAttrStatusList('objectType')
    })
    state.identityList = computed(() => {
      if (['40', '60'].includes(state.status)) return getAttrStatusList('personAndCompany')
      return getAttrStatusList(state.editForm.holderType === '00' ? 'holderPersonSelect' : 'holderCompanySelect')
    })
    state.certTypeList = computed(() => {
      return getAttrStatusList(state.editForm.holderType === '00' ? 'certificateType' : 'companyCertificateType')
    })
    state.sexTypeList = computed(() => {
      return getAttrStatusList('sex')
    })
    state.beneficialCertificateList = computed(() => {
      return getAttrStatusList('certificateType')
    })

    const finalEdit = (currentIndex) => {
      if (currentIndex) {
        state.editForm.finalBeneficialReqs = state.editForm.finalBeneficialReqs.filter((item, index) => index !== currentIndex)
      } else {
        state.editForm.finalBeneficialReqs.push({
          beneficialName: '',
          beneficialArea: null,
          beneficialCertificateType: null,
          beneficialCertificateNumber: '',
          beneficialDate: null,
          beneficialSex: null
        })
      }
    }

    const holderTypeChange = (e) => {
      state.editForm.holderIdentity = null
      state.editForm.reserve2 = null
      state.editForm.holderArea = null
      holderAreaChange()
    }

    const holderAreaChange = () => {
      if (uploadCRef.value) {
        uploadCRef.value.fileData = []
        uploadCRef.value.fileList = []
      }
      state.editForm.holderName = ''
      state.editForm.holderNameEn = ''
      state.editForm.holderCertificateType = null
      state.editForm.holderCertificate = ''
      state.editForm.holderCertificateNumber = ''
      state.editForm.holderRealAddress = ''
      state.editForm.holderRegistratDate = null
      state.editForm.holderSex = null
      state.editForm.finalBeneficialReqs = [{
        beneficialName: '',
        beneficialArea: null,
        beneficialCertificateType: null,
        beneficialCertificateNumber: '',
        beneficialDate: null,
        beneficialSex: null
      }]
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          if (state.editForm.id) return _receive.reAddReceiveHolder(state.editForm)
          else return _receive.addReceiveHolder(state.editForm)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_receive_051'))
            router.back(-1)
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => {
          console.log(err)
          message.error(i18n.global.t('common_text_030'))
          state.loading = false
        })
    }

    const getAreaList = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) state.areaList = res.data.data
    }

    const loadHoldersDetail = async (id, status) => {
      const res = await _receive.queryReHolderDetail({ id, status })
      if (res.data.succ) {
        const { data } = res.data
        state.editForm = Object.assign({}, data.holderDetail)
        state.rejectAttr = data.list || []
        if (data.holderDetail.holderProveDocument && uploadRef.value) uploadRef.value.fileListRefresh(data.holderDetail.holderProveDocument.split(','))
        nextTick(() => {
          if (data.holderDetail.holderCertificate && uploadCRef.value) uploadCRef.value.fileListRefresh(data.holderDetail.holderCertificate.split(','))
        })
      }
    }

    const init = () => {
      getAreaList()
      const { id, status } = route.query
      state.status = status
      if (id) {
        state.editForm.id = id
        loadHoldersDetail(id, status)
      }
    }

    init()

    return {
      editRef,
      uploadRef,
      uploadCRef,
      finalEdit,
      holderTypeChange,
      holderAreaChange,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#holderEdit {
  .common-card-body {
    padding: 24px 0;
    .holder-edit-form {
      width: 748px;
      text-align: left;
      margin: 0 auto;
      .basic-info {
        font-size: 15px;
        padding-bottom: 6px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 18px;
        font-weight: 600;
      }
      .final-area {
        padding: 24px;
        border-radius: 7px;
        box-shadow: 0 2px 6px 4px rgba(0, 0, 0, 0.04);
        margin-bottom: 24px;
      }
    }
    .form-btn {
      width: 100%;
      padding-right: 88px;
      margin-top: 12px;
    }
  }
}
</style>
